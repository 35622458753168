import React from "react"
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageMissionSection from "../sections/PageMissionSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default () => (
  <PageContainer>
    <HeadData 
      title='Mission Statement' 
      description='Interpacific Asset Management is in the business to assume responsibility for the economic performance of the assets under its charge, while delivering exceptional customer service to our clients.'
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='Mission Statement'
      />
    </PageHeaderImageBg>

    <PageMissionSection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)
