import React from 'react' 
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 50px;
  margin-bottom: 22px;
`

const PageMissionSection = () => (  
  <div className='bg-gray-200'>
    <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
      <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management is in the business to assume responsibility for the economic performance of the assets under its charge, while delivering exceptional customer service to our clients.</h2>

      <StyledUnderline></StyledUnderline>

      <h2 className="font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6">Governing Values</h2>

      <div className='w-9/12 sm:w-full'>
        <ol className='ml-12 list-decimal'>
          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>As managers, we seek to be effective as well as efficient.</span><br/>
            Efficiency is concerned with doing things right. Effectiveness is doing the right things. While efficiency is a requisite for survival, effectiveness forms the groundwork for success
          </li>

          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>We produce quality.</span><br/>
            In both the workplace and the marketplace, we provide quality experience and services.
          </li>

          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>We serve the client.</span><br/>
            We are client driven and, in concert with our other values, we give clients what they seek.
          </li>

          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>We wisely manage corporate resources.</span><br/>
            As a corporation and as individuals, we sense a stewardship to use wisely our human resources and our material assets for the purpose of fulfilling the company's vision.
          </li>

          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>We value our employees.</span><br/>
            We recognize that Interpacific Asset Management is only as good as its employees. It is our company’s commitment to provide a productive and fulfilling career for all of Interpacific's employees.
          </li>

          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>We welcome innovation and adapt to change.</span><br/>
            We constantly evaluate what we are doing and compare it to what is possible and what is needed. Nothing in society remains the same; neither can we.
          </li>

          <li className='text-xl mb-4 text-gray-900'>
            <span className='font-bold'>We practice teamwork.</span><br/>
            We consider those who work for, or associate with, Interpacific Asset Management as members of our team. We recognize that each client, vendor, tenant, homeowner, or employee has a unique contribution to make to our team effort.
          </li>
        </ol>

        <p className='text-xl mt-8 text-gray-900'>These are Interpacific Asset Managements' highest priorities. Meeting them will enable us to realize our mission.</p>
      </div>
    </div>
  </div>      
)

export default PageMissionSection